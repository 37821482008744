<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="180px" :model="formLabelAlign">
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="姓名："
              prop="truename"
              :rules="[{ required: true, message: '请输入姓名' }]"
            >
              <el-input
                placeholder="请输入姓名"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.truename"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="班级："
              prop="class_id"
              :rules="[{ required: true, message: '请输入班级搜索选择' }]"
            >
              <el-select
                :value="class_value"
                class="form-inp"
                v-model="formLabelAlign.class_id"
                filterable
                remote
                reserve-keyword
                placeholder="请输入班级名"
                :remote-method="remoteMethod"
              >
                <el-option
                  v-for="item in class_Data"
                  :key="item.id"
                  :label="item.class_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学号：">
              <el-input
                placeholder="请输入学号"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.stu_no"
              ></el-input>
            </el-form-item>
          </el-col>

         
          <el-col :span="12">
            <el-form-item
              label="性别："
              prop="gender"
              :rules="[{ required: true, message: '请选择性别' }]"
            >
              <el-select
                allow-create=""
                class="form-inp"
                v-model="formLabelAlign.gender"
                placeholder="请选择性别"
              >
                <el-option
                  v-for="item in gender_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="身份证号："
              prop="id_card"
              :rules="[{ required: true, message: '请输入身份证号' }]"
            >
              <el-input
                placeholder="请输入身份证号"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.id_card"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="住址："
              prop="address"
              :rules="[{ required: true, message: '请输入住址' }]"
            >
              <el-input
                placeholder="请输入住址"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.address"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="家庭住址：">
              <el-input
                placeholder="请输入家庭住址"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.home_address"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="联系手机号："
              prop="phone"
              :rules="[{ required: true, message: '请输入联系手机号' }]"
            >
              <el-input
                placeholder="请输入联系手机号"
                size="mini"
                class="form-inp"
                v-model.number="formLabelAlign.phone"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="紧急联系人1："
              prop="em_contact1"
              :rules="[{ required: true, message: '请输入紧急联系人1' }]"
            >
              <el-input
                placeholder="请输入紧急联系人1"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.em_contact1"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="紧急联系人1手机号："
              prop="em_contact1_tel"
              :rules="[{ required: true, message: '紧急联系人1手机号' }]"
            >
              <el-input
                placeholder="紧急联系人1手机号"
                size="mini"
                class="form-inp"
                v-model.number="formLabelAlign.em_contact1_tel"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="紧急联系人2：">
              <el-input
                placeholder="请输入紧急联系人2"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.em_contact2"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="紧急联系人2手机号：" prop="em_contact2_tel">
              <el-input
                placeholder="紧急联系人2手机号"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.em_contact2_tel"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注说明">
              <el-input
                placeholder="备注说明"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.desc"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="学生状态："
              prop="status"
              :rules="[{ required: true, message: '请选择状态' }]"
            >
              <el-select
                class="form-inp"
                v-model="formLabelAlign.status"
                placeholder="请选择学生状态"
              >
                <el-option
                  v-for="item in status_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  studentsaveInfo,
  banjisearch,
  studentsave,
} from "../../assets/request/api";
export default {
  name: "student_redacy",
  data() {
    return {
      type_name: "保存",
      //要修改的id
      id: "",
      formLabelAlign: {
        truename: "",
        id_card: "",
        gender: "",
        address: "",
        home_address: "",
        phone: "",
        em_contact1: "",
        em_contact1_tel: "",
        em_contact2: "",
        em_contact2_tel: "",
        desc: "",
        status: 0,
        stu_no:"",
        class_id: "",
      },
      status_list: [],
      gender_list: [],
      class_Data: [],
      class_value: "",
    };
  },
  async created() {
    let { id } = this.$route.query;
    this.way_show(id);
  },
  methods: {
    way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      return studentsaveInfo(froms).then((res) => {
        let { info, status_list, gender_list } = res.data;
        this.status_list = status_list;
        this.gender_list = gender_list;
        if (id) {
          this.id = id;
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let {
            truename,
            id_card,
            gender,
            address,
            home_address,
            phone,
            em_contact1,
            em_contact1_tel,
            em_contact2,
            em_contact2_tel,
            desc,
            status,
            class_id,
            stu_no
          } = info;
          banjisearch({ class_name: info.pre_name }).then((res) => {
            let { data } = res;
            this.class_Data = data;
            formLabelAlign.truename = truename;
            formLabelAlign.id_card = id_card;
            formLabelAlign.gender = gender;
            formLabelAlign.address = address;
            formLabelAlign.phone = phone;
            formLabelAlign.stu_no = stu_no;
            formLabelAlign.em_contact1 = em_contact1;
            formLabelAlign.em_contact1_tel = em_contact1_tel;
            formLabelAlign.em_contact2 = em_contact2;
            formLabelAlign.desc = desc;
            formLabelAlign.status = status;
            formLabelAlign.em_contact2_tel = em_contact2_tel;
            formLabelAlign.status = status;
            formLabelAlign.class_id = Number(class_id);
            formLabelAlign.home_address = home_address;
          });
        }
      });
    },
    //查询班级
    remoteMethod(query) {
      if (query !== "") {
        banjisearch({ class_name: query }).then((res) => {
          let { data } = res;
          this.class_Data = data;
        });
      } else {
        this.class_Data = [];
      }
    },
    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { formLabelAlign, id } = this;
          if (id) {
            formLabelAlign.id = id;
          }
          studentsave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              setTimeout(function () {
                that.$router.go(-1);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
</style>